/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


/* CSS Stylesheet Example */
// .card-app-design:hover {
// 	background-color: #f0f0f0; /* Change this to the desired background color on hover */
// 	transition: background-color 0.3s ease; /* Add a smooth transition effect */
//   }
  
.applicationCard:hover {
	background-color: rgba(115, 103, 240, 0.7); 
	transition: background-color 0.3s ease; 	
}
.applicationCard:hover .applicationCardHeading {
	color: #fff; 
}

.applicationCard:hover .applicationbadge{
	color: #fff;
}

		// .fixed-column {
		// 	position: sticky;	
		// 	// border: 2px solid #798fce;
		// }

		// .scroll-column{
		// 	overflow-y: scroll;
		// 	height: 1200px;
		// }

		.custom {
			// overflow-y: scroll;
		  }
		  
		  .fixed-header {
			position: fixed; /* Fixed position at the top */
			// top: -5;
			margin-top: -200px;
			left: 30;
			width: 48%;
			overflow-y: scroll;
			height: 700px;
			transition: position 0.8s ease;
		  }